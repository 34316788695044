import React from 'react';
import { Link } from 'react-router-dom';

class News extends React.Component {
  render() {
    return (
      <div class="content">
        <h3 class="introduction">Monsterland Meka is a modern re-imagining of the 1980’s SEGA video game “Wonder Boy In Monster Land”.</h3>
        <h2>News</h2>

        <h3>Sunday 30th October 2022</h3>
        <p>A new website, and the alpha demo version is available to download. Visit the <Link to="/download" onClick={this.forceUpdate}>download</Link> page for more details.</p>
      </div>
    );
  }
}

export default News;
