import React from 'react';
import ReactImageVideoLightbox from 'react-image-video-lightbox';

const lightboxConfig = [
  { type: 'video', image: 'https://img.youtube.com/vi/ybZggtFLx-k/mqdefault.jpg', url: 'https://www.youtube.com/embed/ybZggtFLx-k', title: 'Tuna Trouble' },
  { type: 'video', image: 'https://img.youtube.com/vi/NUnQ9oPN_rI/mqdefault.jpg', url: 'https://www.youtube.com/embed/NUnQ9oPN_rI', title: 'Shopping' },
  { type: 'video', image: 'https://img.youtube.com/vi/Hkw0G2c3rkA/mqdefault.jpg', url: 'https://www.youtube.com/embed/Hkw0G2c3rkA', title: 'Skateboarding part 2' },
  { type: 'video', image: 'https://img.youtube.com/vi/e2GzlGOX_h8/mqdefault.jpg', url: 'https://www.youtube.com/embed/e2GzlGOX_h8', title: 'Skateboarding' },
  { type: 'video', image: 'https://img.youtube.com/vi/CARpsl0NpuM/mqdefault.jpg', url: 'https://www.youtube.com/embed/CARpsl0NpuM', title: 'August 2020 Updates' },
  { type: 'video', image: 'https://img.youtube.com/vi/PxXtkVonkOA/mqdefault.jpg', url: 'https://www.youtube.com/embed/PxXtkVonkOA', title: 'Preview' },
  { type: 'photo', image: 'photos/thumb/Feb7.png', url: 'photos/Feb7.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Feb6.png', url: 'photos/Feb6.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Feb5.png', url: 'photos/Feb5.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Feb4.png', url: 'photos/Feb4.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Feb3.png', url: 'photos/Feb3.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Feb2.png', url: 'photos/Feb2.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Feb1.png', url: 'photos/Feb1.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/January8.png', url: 'photos/January8.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/January7.png', url: 'photos/January7.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/January6.png', url: 'photos/January6.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/January5.png', url: 'photos/January5.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/January4.png', url: 'photos/January4.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/January3.png', url: 'photos/January3.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/January2.png', url: 'photos/January2.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/January1.png', url: 'photos/January1.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/November1.png', url: 'photos/November1.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/October6.png', url: 'photos/October6.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/October5.png', url: 'photos/October5.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/October4.png', url: 'photos/October4.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/October3.png', url: 'photos/October3.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/October2.png', url: 'photos/October2.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/October1.png', url: 'photos/October1.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/DeathMaster1.png', url: 'photos/DeathMaster1.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/DeathMaster2.png', url: 'photos/DeathMaster2.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/DeathMaster3.png', url: 'photos/DeathMaster3.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/DeathMaster4.png', url: 'photos/DeathMaster4.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/DeathMaster5.png', url: 'photos/DeathMaster5.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Bar2.png', url: 'photos/Bar2.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Bar1.png', url: 'photos/Bar1.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Start8.png', url: 'photos/Start8.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Start6.png', url: 'photos/Start6.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Start5.png', url: 'photos/Start5.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Start4.png', url: 'photos/Start4.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Start3.png', url: 'photos/Start3.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Start2.png', url: 'photos/Start2.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Start1.png', url: 'photos/Start1.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Screenshot2-1.png', url: 'photos/Screenshot2-1.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Screenshot3-1.png', url: 'photos/Screenshot3-1.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Screenshot4-1.png', url: 'photos/Screenshot4-1.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/Screenshot5-1.png', url: 'photos/Screenshot5-1.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/ValleyOfPeace4.png', url: 'photos/ValleyOfPeace4.png', altTag: 'World Map' },
  { type: 'photo', image: 'photos/thumb/ValleyOfPeace.png', url: 'photos/ValleyOfPeace.png', altTag: 'World Map' }
];

class Home extends React.Component {
  state = {
    lightboxOpen: false,
    index: 0
  };

  render() {
    return (
      <div class="content">

        <h3 class="introduction">Monsterland Meka is a modern re-imagining of the 1980’s SEGA video game “Wonder Boy In Monster Land”.</h3>

        <h2>Video Gallery</h2>
        <div class="videoContent">
        {
          lightboxConfig.map((content, index) => {
            if (content.type === 'video') {
              return (
                <div class="videoLink" tabindex="0">
                  <button onClick={() => this.setState({ lightboxOpen: true, index: index })} style={{ backgroundImage: "url(" + content.image + ")" }} />
                </div>
              );
            }
            return '';
          })
        }
        </div>

        <h2>Photo Gallery</h2>
        <div class="photoContent">
        {
          lightboxConfig.map((content, index) => {
            if (content.type === 'photo') {
              return (
                <div class="photoLink" tabindex="0">
                  <button onClick={() => this.setState({ lightboxOpen: true, index: index })} style={{ backgroundImage: "url(" + content.image + ")" }} />
                </div>
              );
            }
            return '';
          })
        }
        </div>

        {this.state.lightboxOpen && (
          <ReactImageVideoLightbox data={lightboxConfig} startIndex={this.state.index} showResourceCount={true} onCloseCallback={() => this.setState({ lightboxOpen: false })} />
        )}

      </div>
    );
  }
}

export default Home;
