import React from 'react';
import Header from './Header';
import Main from './Main';
import Links from './Links';

import './App.css';

class App extends React.Component {
  render() {
    return (
      <div className="app">
        <Header />
        <div class="row">
          <Main />
          <Links />
        </div>
      </div>
    );
  }
}

export default App;
