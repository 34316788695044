import React from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'

class Links extends React.Component {
  render() {
    return (
      <div class="links">
        <div class="localLinkContent">
          <Router>
            <div class="localLink"><Link to="/" onClick={this.forceUpdate}>Home</Link></div>
            <div class="localLink"><Link to="/news" onClick={this.forceUpdate}>News</Link></div>
            <div class="localLink"><Link to="/about" onClick={this.forceUpdate}>About</Link></div>
            <div class="localLink"><Link to="/download" className="download" onClick={this.forceUpdate}>Download</Link></div>
          </Router>
        </div>
        <div class="socialLinkContent">
          <div class="socialLink"><a href="https://twitter.com/monsterlandmeka"><FontAwesomeIcon icon={faTwitter} size="2x" className="fa" /></a></div>
          <div class="socialLink"><a href="https://www.youtube.com/channel/UCdECxVDLRDi0AD-hZKTEltA"><FontAwesomeIcon icon={faYoutube} size="2x" className="fa" /></a></div>
        </div>
      </div>
    );
  }
}

export default Links;
