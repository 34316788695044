import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Home from './Home';
import News from './News';
import About from './About';
import Download from './Download';

const Main = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/news' element={<News/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/?page_id=2' element={<About/>}/>
        <Route path='/download' element={<Download/>}/>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default Main;
